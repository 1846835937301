import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

function Settings() {
  const [activeDisplay, setActiveDisplay] = useState("display1");
  const [adminProfile, setAdminProfile] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const cache = useRef(null); // Cache to store fetched admin profile data

  const token = useMemo(() => localStorage.getItem("accessToken"), []);
  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: "/super-admin",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    [token]
  );

  const fetchAdminProfileData = useCallback(async () => {
    setLoading(true);
    try {
      if (cache.current) {
        setAdminProfile(cache.current);
      } else {
        const response = await axiosInstance.get("/get-admin-profile-data");
        setAdminProfile(response.data.message.admin);
        cache.current = response.data.message.admin; // Cache the data
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }, [axiosInstance]);

  // Fetch data immediately on component mounttt
  useEffect(() => {
    fetchAdminProfileData();
  }, [fetchAdminProfileData]);

  const handleFileChange = useCallback(
    debounce(async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      setSelectedFile(file);

      const formData = new FormData();
      formData.append("user_id", adminProfile.id);
      formData.append("profile_image", file);
      formData.append("f_name", adminProfile.f_name);
      formData.append("l_name", adminProfile.l_name);
      formData.append("phone", adminProfile.phone);
      formData.append("email", adminProfile.email);
      formData.append("address", adminProfile.address);
      formData.append("nin", adminProfile.nin);

      try {
        const response = await axiosInstance.post(
          "/update-user-details",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Log the full response object
        console.log("API Response:", response);

        // Log specific parts of the response if needed
        console.log("Response Data:", response.data);
        console.log("Response Status:", response.status);
        console.log("Response Headers:", response.headers);

        cache.current = null;
        fetchAdminProfileData();
      } catch (error) {
        console.error("Error updating profile picture:", error);
      }
    }, 300),
    [axiosInstance, fetchAdminProfileData, adminProfile]
  );

  const handlePasswordChange = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    const data = {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    try {
      await axiosInstance.post("/reset-user-password", data);
      setResetSuccess(true);
      alert("Password reset successful!");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.log("Error resetting password:", error.response);
      if (error.response) {
        alert(`Error: ${error.response.data.message}`);
      } else {
        alert("Network error. Please try again.");
      }
    }
  };

  useEffect(() => {
    if (activeDisplay === "display1") {
      fetchAdminProfileData();
    }
  }, [activeDisplay, fetchAdminProfileData]);

  return (
    <div className="dashboard">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display">
        <div className="top-bar container-fluid">
          <div
            className="top-bar-info"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link to="/dashboard">
              <img
                src={`${process.env.PUBLIC_URL}/images/arrow-left.png`}
                alt=""
              />
            </Link>
            <b>
              <p style={{ margin: 0 }}>
                {activeDisplay === "display1"
                  ? "Personal Information"
                  : "Change Password"}
              </p>
            </b>
          </div>
        </div>
        <div className="main-settings">
          <div className="settings-nav">
            <button
              className={activeDisplay === "display1" ? "active" : ""}
              onClick={() => setActiveDisplay("display1")}
            >
              <img
                src={
                  activeDisplay === "display1"
                    ? `${process.env.PUBLIC_URL}/images/settings-user-active.png`
                    : `${process.env.PUBLIC_URL}/images/settings-user-inactive.png`
                }
                alt=""
              />
              Personal Information
            </button>
            <button
              className={activeDisplay === "display2" ? "active" : ""}
              onClick={() => setActiveDisplay("display2")}
            >
              <img
                src={
                  activeDisplay === "display2"
                    ? `${process.env.PUBLIC_URL}/images/settings-lock-active.png`
                    : `${process.env.PUBLIC_URL}/images/settings-lock-inactive.png`
                }
                alt=""
              />
              Change Password
            </button>
          </div>
          <div className="settings-display">
            {activeDisplay === "display1" && (
              <div className="settings-display1">
                <h2>Personal Information</h2>
                <div className="profile-picture">
                  {loading ? (
                    <div
                      className="spinner"
                      style={{
                        margin: "0",
                        padding: "0",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    ></div> // Show spinner while loading
                  ) : (
                    <img
                      src={
                        adminProfile.profile_image ||
                        `${process.env.PUBLIC_URL}/images/settings-avatar.png`
                      }
                      alt="Profile"
                    />
                  )}
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the file input
                      id="fileInput"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      Change picture
                    </button>
                    <p>JPG or PNG. 1MB max.</p>
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-fields">
                    <div className="field-group">
                      <div className="field">
                        <label>First Name</label>
                        <p>{loading ? "..." : adminProfile.f_name}</p>
                      </div>
                      <div className="field">
                        <label>Last Name</label>
                        <p>{loading ? "..." : adminProfile.l_name}</p>
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label>Email</label>
                        <p>{loading ? "..." : adminProfile.email}</p>
                      </div>
                      <div className="field">
                        <label>Staff ID Number</label>
                        <p style={{ color: "#2A6FF1" }}>
                          {loading ? "..." : `#${adminProfile.id}`}
                        </p>
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label>Phone Number</label>
                        <p>{loading ? "..." : adminProfile.phone}</p>
                      </div>
                      <div className="field">
                        <label>National Identification Number (NIN)</label>
                        <p>{loading ? "..." : adminProfile.nin}</p>
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label>Residential Address</label>
                        <p>{loading ? "..." : adminProfile.address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeDisplay === "display2" && (
              <div className="settings-display2">
                <h2>Change Password</h2>
                <form
                  className="password-change-form"
                  onSubmit={handlePasswordChange}
                >
                  <div className="settings-form-group">
                    <label htmlFor="old-password">Old Password</label>
                    <input
                      type="password"
                      id="old-password"
                      name="old-password"
                      required
                      className="password-input"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </div>
                  <div className="settings-form-group">
                    <label htmlFor="new-password">New Password</label>
                    <input
                      type="password"
                      id="new-password"
                      name="new-password"
                      required
                      className="password-input"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="settings-form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input
                      type="password"
                      id="confirm-password"
                      name="confirm-password"
                      required
                      className="password-input"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit">Save Password</button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
