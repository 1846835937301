import React from "react";

const Pagination = ({
  currentPageProp,
  totalPageProp,
  onPageChange,
  loading,
}) => {
  const generatePageNumbers = () => {
    const pageNumbers = [];

    if (totalPageProp <= 5) {
      // If there are 5 or fewer pages, show all pages
      for (let i = 1; i <= totalPageProp; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show the first page
      pageNumbers.push(1);

      // Show ellipsis if current page > 3
      if (currentPageProp > 3) {
        pageNumbers.push("...");
      }

      // Pages around the current page
      const startPage = Math.max(currentPageProp - 1, 2);
      const endPage = Math.min(currentPageProp + 1, totalPageProp - 1);
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // Show ellipsis if the current page isn't near the end
      if (currentPageProp < totalPageProp - 2) {
        pageNumbers.push("...");
      }

      // Always show the last page
      pageNumbers.push(totalPageProp);
    }

    return pageNumbers;
  };

  const handlePageClick = (page) => {
    if (page !== "..." && !loading) {
      onPageChange(page); // Only allow page change if not loading
    }
  };

  return (
    <div className="pagination staff-pagination">
      <div>
        {/* Previous button */}
        <button
          onClick={() => onPageChange(currentPageProp - 1)}
          disabled={currentPageProp === 1 || loading}
        >
          {loading ? (
            <span className="loading-spinner">⏳</span> // Add a spinner or loading icon
          ) : (
            "<"
          )}
        </button>

        {/* Page numbers */}
        {generatePageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(page)}
            disabled={page === "..." || loading}
            className={page === currentPageProp ? "active" : ""}
          >
            {page}
          </button>
        ))}

        {/* Next button */}
        <button
          onClick={() => onPageChange(currentPageProp + 1)}
          disabled={currentPageProp === totalPageProp || loading}
        >
          {loading ? (
            <span className="loading-spinner">⏳</span> // Add a spinner or loading icon
          ) : (
            ">"
          )}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
