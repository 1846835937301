import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";

function QrCode() {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getToken = () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No access token found");
      setIsLoading(false);
    }
    return token;
  };

  const handleError = (error) => {
    if (error.response) {
      console.error("Error fetching QR code:", error.response.data);
    } else if (error.request) {
      console.error("Error fetching QR code:", error.request);
    } else {
      console.error("Error fetching QR code:", error.message);
    }
    setIsLoading(false);
  };

  const fetchQrCode = () => {
    const token = getToken();
    if (!token) return;

    axios
      .get("/super-admin/get-qr-code", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const qrCodePath = response.data.message.qr_code_path;
        setUrl(qrCodePath);
        localStorage.setItem("qrCodeUrl", qrCodePath); // Cache the QR code URL
        setIsLoading(false);
      })
      .catch(handleError);
  };

  const regenerateQr = () => {
    const token = getToken();
    if (!token) return;

    axios
      .post(
        "/super-admin/generate-qr-code",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const qrCodePath = response.data.message.path;
        setUrl(qrCodePath);
        localStorage.setItem("qrCodeUrl", qrCodePath); // Update the cached QR code URL
        setIsLoading(false);
      })
      .catch(handleError);
  };

  useEffect(() => {
    const cachedUrl = localStorage.getItem("qrCodeUrl");
    if (cachedUrl) {
      setUrl(cachedUrl);
      setIsLoading(false);
    } else {
      fetchQrCode();
    }
  }, []);

  const handleDownload = () => {
    window.open(url, "_blank");
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="dashboard">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display">
        <div className="top-bar container-fluid">
          <div
            className="top-bar-info"
            style={{ display: "flex", alignItems: "center" }}
          >
            <b>
              <p style={{ margin: 0 }}>Generate QR code</p>
            </b>
          </div>
        </div>
        <div className="main-qr">
          <div className="qr-body">
            <h1>Scan QR code</h1>
            <div className="qr-container">
              {isLoading ? <div className="spinner"></div> : null}
              <img
                src={url}
                alt="QR Code"
                style={{ display: isLoading ? "none" : "block" }}
                onLoad={handleImageLoad}
              />
            </div>
            <br />
            <button
              onClick={() => {
                setIsLoading(true);
                regenerateQr();
              }}
            >
              Refresh QR Code
            </button>
            <br />
            <button onClick={handleDownload}>Download QR Code</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCode;
