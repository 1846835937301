import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const email = localStorage.getItem("email"); // Retrieve email from localStorage
  const otp = localStorage.getItem("otp"); // Retrieve OTP from localStorage
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      alert(
        "Passwords do not match. Please ensure both passwords are the same."
      );
      return;
    }

    if (!email || !otp) {
      alert("Email or OTP is missing. Please ensure both are provided.");
      return;
    }

    const payload = {
      email,
      otp,
      password,
      password_confirmation: confirmPassword,
    };

    // return;
    try {
      setLoading(true);
      // Replace with your actual backend API endpoint for password reset
      const response = await axios.post(
        "/super-admin/reset-password",
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.type === "success") {
        alert("Password has been reset successfully.");
        navigate("/dashboard"); // Navigate to dashboard upon successful password reset
      } else {
        alert("Failed to reset password. Please try again later.");
        navigate("/request-otp");
      }
    } catch (error) {
      alert("Failed to reset password. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="password-reset">
      <div className="reset-input">
        <div className="reset-input-main container">
          <h1>Password Reset</h1>
          <p>Input your details to reset the password</p>
          <form className="password-reset-form" onSubmit={handlePasswordReset}>
            <div className="form-group req">
              <b>
                <label htmlFor="password">New Password</label>
              </b>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your new password"
              />
            </div>
            <div className="form-group req">
              <b>
                <label htmlFor="confirmPassword">Confirm Password</label>
              </b>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm your new password"
              />
            </div>
            <button type="submit" className="reset-button" disabled={loading}>
              {loading ? "Resetting Password..." : "Reset Password"}
            </button>
          </form>
        </div>
      </div>

      <div className="login-image2">
        {/* Optional image or any additional content */}
      </div>
    </div>
  );
}

export default ResetPassword;
