import React, { createContext, useState } from "react";

export const MemberContext = createContext();

export const MemberProvider = ({ children }) => {
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  return (
    <MemberContext.Provider value={{ selectedMemberId, setSelectedMemberId }}>
      {children}
    </MemberContext.Provider>
  );
};
