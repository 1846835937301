import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { formatDistanceToNow, parseISO } from "date-fns";
import "react-calendar/dist/Calendar.css";
import Sidebar from "./Sidebar";
import Modal from "react-modal";

function Notifications() {
  const adminData = sessionStorage.getItem("adminName");
  const [notifications, setNotifications] = useState([]),
    [searchTerm, setSearchTerm] = useState(""),
    [searchQuery, setSearchQuery] = useState(""),
    [notifApprove, setNotifApprove] = useState(false),
    [notifDecline, setNotifDecline] = useState(false),
    [viewModal, setViewModal] = useState(false),
    [viewModal2, setViewModal2] = useState(false),
    [approveSuccess, setApproveSuccess] = useState(false),
    [declineSuccess, setDeclineSuccess] = useState(false),
    [notifPreview, setNotifPreview] = useState(false),
    [sendNotifSuccess, setSendNotifSuccess] = useState(false),
    [currentTnxRef, setCurrentTnxRef] = useState(null),
    [currentId, setCurrentId] = useState(null),
    [paymentDetails, setPaymentDetails] = useState(null),
    [password, setPassword] = useState(""),
    [messageHeader, setMessageHeader] = useState(""),
    [message, setMessage] = useState(""),
    [loading, setLoading] = useState(false);

  const [fileSize, setFileSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const currentDate = useMemo(() => new Date(), []);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );
  const dayOfWeekIndex = currentDate.getDay(),
    monthIndex = currentDate.getMonth(),
    dayOfMonth = currentDate.getDate();
  const formattedDate = `${daysOfWeek[dayOfWeekIndex]}, ${monthsOfYear[monthIndex]} ${dayOfMonth}`;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(
          "/super-admin/get-app-notifications",
          config
        );
        setNotifications(response.data.message.notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  const filteredNotifications = notifications.filter(
    (notification) =>
      notification.message_header
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      notification.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // If the search input is cleared, reset the search query to show all notifications
    if (value === "") {
      setSearchQuery("");
    }
  };
  const handleSearchClick = () => setSearchQuery(searchTerm);

  useEffect(() => {
    if (filteredNotifications.length === 0 && searchQuery) {
      const timeoutId = setTimeout(() => {
        setSearchQuery(""); // Reset the search query
        setSearchTerm(""); // Clear the search input field
      }, 2000);

      return () => clearTimeout(timeoutId); // Cleanup the timeout if the component unmounts or searchQuery changes
    }
  }, [filteredNotifications, searchQuery]);

  const fetchPaymentDetails = async (tnxRef) => {
    const token = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const response = await axios.post(
        "/super-admin/get-subscription-payment-detail",
        { tnx_ref: tnxRef },
        config
      );
      setPaymentDetails(response.data.message);
    } catch (error) {
      console.error("Error fetching payment details:", error);
    }
  };

  const notifPreviewOpen = () => {
    setNotifPreview(true);
  };

  const notifApproveOpen = async (tnxRef, id) => {
    setCurrentTnxRef(tnxRef);
    setCurrentId(id);
    await fetchPaymentDetails(tnxRef);
    setNotifApprove(true);
  };

  const notifDeclineOpen = async (tnxRef, id) => {
    setCurrentTnxRef(tnxRef);
    setCurrentId(id);
    await fetchPaymentDetails(tnxRef);
    setNotifDecline(true);
  };

  const handleApprovePayment = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const response = await axios.post(
        "/super-admin/approve-subscription-payment",
        { tnx_ref: currentTnxRef, password, notification_id: currentId },
        config
      );
      setApproveSuccess(true);
      setViewModal(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.type === "error"
      ) {
        alert(error.response.data.message);
        setViewModal(false);
      } else {
        console.error("Error approving payment:", error);
      }
    }
  };

  const handleDeclinePayment = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const response = await axios.post(
        "/super-admin/decline-subscription-payment",
        { tnx_ref: currentTnxRef, password, notification_id: currentId },
        config
      );
      setDeclineSuccess(true);
      setViewModal2(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.type === "error"
      ) {
        alert(error.response.data.message);
        setViewModal(false);
      } else {
        console.error("Error declining payment:", error);
      }
    }
  };

  const validateAndSetImage = (file) => {
    const validTypes = ["image/jpeg", "image/png"];
    const maxSize = 4 * 1024 * 1024; // 4MB in bytes

    if (!validTypes.includes(file.type)) {
      alert("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (file.size > maxSize) {
      alert("File size exceeds 4mb. Please upload a smaller file.");
      return;
    }

    const fileSizeInKB = (file.size / 1024).toFixed(2);
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);

    setSelectedImage(file);
    setFileSize(fileSizeInMB < 1 ? `${fileSizeInKB} KB` : `${fileSizeInMB} MB`);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    validateAndSetImage(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    validateAndSetImage(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handlePreviewMessage = (e) => {
    e.preventDefault();
    setNotifPreview(true);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setFileSize(null);
  };

  const closePreview = () => {
    setNotifPreview(false);
  };

  const handleSendNotification = async (e) => {
    e.preventDefault();

    const button = e.target; // Access the clicked button
    button.textContent = "Sending...";

    const token = localStorage.getItem("accessToken");
    const config = { headers: { Authorization: `Bearer ${token}` } };

    const formData = new FormData();
    formData.append("message_header", messageHeader);
    formData.append("message", message);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "/super-admin/create-app-notification",
        formData,
        config
      );
      setNotifPreview(false);
      setSendNotifSuccess(true);
      setMessageHeader("");
      setMessage("");
      setSelectedImage(null); // Reset the image after successful submission
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      setLoading(false);
    }
  };

  const getInitials = (header) =>
    header
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join("")
      .slice(0, 2);

  return (
    <div className="dashboard container-fluid notif-dashboard">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display notif-container-display container-fluid">
        <div className="top-bar container-fluid">
          <div className="top-bar-info">
            <p id="date">{formattedDate}</p>
            <b>
              <p>Welcome back {adminData}</p>
            </b>
          </div>
        </div>
        <div className="notification-container container-fluid">
          <div className="show-notifications">
            <div className="notif-head">
              <h2>Notifications</h2>
            </div>
            <div className="notif-search">
              <input
                type="text"
                placeholder="Search fields"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
              <button className="search-btn" onClick={handleSearchClick}>
                Search
              </button>
            </div>
            <div className="notif-body">
              {loading ? (
                <div className="spinner" style={{ marginTop: "40%" }}></div>
              ) : filteredNotifications.length > 0 ? (
                filteredNotifications.map((notification, index) => (
                  <div key={index} className="notif">
                    <div className="initials-container">
                      <div
                        className="notif-initials"
                        style={{
                          backgroundColor: "#0473BD",
                          color: "white",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {getInitials(notification.message_header)}
                      </div>
                    </div>
                    <div className="notif-text">
                      <p style={{ fontWeight: "bolder", marginBottom: "8px" }}>
                        {notification.message_header}
                      </p>
                      <p>{notification.message}</p>

                      {notification.additional_data?.subscription_tnx_ref && (
                        <div className="notif-btn">
                          {notification.additional_data.status === "unpaid" && (
                            <div>
                              <button
                                style={{
                                  color: "#0473BD",
                                  marginRight: "10px",
                                }}
                                onClick={() =>
                                  notifApproveOpen(
                                    notification.additional_data
                                      .subscription_tnx_ref,
                                    notification.id
                                  )
                                }
                              >
                                Approve Payment
                              </button>
                              <button
                                style={{ color: "red" }}
                                onClick={() =>
                                  notifDeclineOpen(
                                    notification.additional_data
                                      .subscription_tnx_ref,
                                    notification.id
                                  )
                                }
                              >
                                Decline Payment
                              </button>
                            </div>
                          )}

                          {notification.additional_data.status === "paid" && (
                            <button style={{ color: "green" }}>Approved</button>
                          )}

                          {notification.additional_data.status ===
                            "declined" && (
                            <button style={{ color: "red" }}>Declined</button>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="notif-time">
                      <p style={{ color: "#737373", fontWeight: "bold" }}>
                        {formatDistanceToNow(
                          parseISO(notification.created_at),
                          { addSuffix: true }
                        )}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="nil-notif">No notifications available.</p>
              )}
            </div>
            <div className="notif-footer"></div>
          </div>
          <div className="send-notifications">
            <h3>Send Notification</h3>
            <p>Send a customized in-app message to Frebson users</p>
            <div className="send-notif-form">
              <form onSubmit={handlePreviewMessage}>
                <div className="notif-header">
                  <label htmlFor="messageHeader">Message Header</label>
                  <input
                    type="text"
                    id="messageHeader"
                    name="messageHeader"
                    value={messageHeader}
                    onChange={(e) => setMessageHeader(e.target.value)}
                    required
                  />
                </div>
                <div className="notif-message-image">
                  <h1>Upload Image (Optional)</h1>
                  {selectedImage ? (
                    <div className="image-upload-success">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/notif-img-upload.png`}
                        alt="upload placeholder"
                      />
                      <div className="upload-success-details">
                        <h1>Image uploaded successfully</h1>
                        <p>File size: {fileSize} </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="image-input-container"
                      onClick={() =>
                        document.getElementById("imageInput").click()
                      }
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/notif-img-upload.png`}
                        alt="upload placeholder"
                      />
                      <h1>Click to upload and drag items</h1>
                      <p>jpeg and png.(Max 4mb)</p>
                    </div>
                  )}
                  <input
                    type="file"
                    id="imageInput"
                    accept="image/png, image/jpeg, application/pdf"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
                <div className="notif-message">
                  <label htmlFor="message">Enter Message</label>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Type a text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </div>
                <div className="notif-button">
                  <button type="submit" className="send-msg-btn">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={notifPreview}
        onRequestClose={closePreview}
        contentLabel="Notification Preview"
        className="add-admin-modal"
      >
        <div className="view-info-inner">
          <div className="add-staff-head view-info-head">
            <h2>Message Preview</h2>
          </div>
          <div className="preview-body">
            <h3>{messageHeader}</h3>
            <p>{message}</p>
            {selectedImage && (
              <div
                className="preview-image-container"
                style={{ position: "relative", display: "inline-block" }}
              >
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
                <div
                  className="remove-image"
                  onClick={handleRemoveImage}
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "-5px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  x
                </div>
              </div>
            )}
          </div>
          <div className="modal-button">
            <button onClick={closePreview}>Edit Message</button>
            <button
              style={{ backgroundColor: "#0473BD", color: "white" }}
              onClick={handleSendNotification}
            >
              Send Message
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={sendNotifSuccess}
        onRequestClose={() => setSendNotifSuccess(false)}
        contentLabel="Notif Success"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "bold" }}>Message Sent</h2>
        <button onClick={() => setSendNotifSuccess(false)}>Close</button>
      </Modal>

      <Modal
        isOpen={notifApprove}
        onRequestClose={() => setNotifApprove(false)}
        contentLabel="Notification Approval"
        className="add-admin-modal approve"
      >
        <div className="add-staff-head">
          <h2>Payment Overview</h2>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/images/approve-payment.png`}
          alt="approve payment"
        />
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Are you sure you want to approve this payment?
        </p>
        {paymentDetails && (
          <div className="details-container container">
            <div className="detail">
              <p>Payer's name</p>
              <b>
                <p className="D">{paymentDetails.payer_name}</p>
              </b>
            </div>
            <div className="detail">
              <p>Gym Number</p>
              <b>
                <p className="D">{paymentDetails.user_id}</p>
              </b>
            </div>
            <div className="detail">
              <p>Payment Method</p>
              <b>
                <p className="D">{paymentDetails.payment_method}</p>
              </b>
            </div>
            <div className="detail">
              <p>Date of Payment</p>
              <b>
                <p className="D">{paymentDetails.date}</p>
              </b>
            </div>
            <div className="detail">
              <p>Amount Paid</p>
              <b>
                <p className="D">₦{paymentDetails.amount}</p>
              </b>
            </div>
          </div>
        )}
        <div className="modal-button">
          <button type="button" onClick={() => setNotifApprove(false)}>
            Back
          </button>
          <button
            type="button"
            onClick={() => setViewModal(true) & setNotifApprove(false)}
            style={{ backgroundColor: "#0473bd", color: "white" }}
          >
            Approve
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={notifDecline}
        onRequestClose={() => setNotifDecline(false)}
        contentLabel="Notification Decline"
        className="add-admin-modal decline"
      >
        <div className="add-staff-head">
          <h2>Payment Overview</h2>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/images/decline-payment.png`}
          alt="decline payment"
        />
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Are you sure you want to decline this payment?
        </p>
        {paymentDetails && (
          <div className="details-container container">
            <div className="detail">
              <p>Payer's name</p>
              <b>
                <p className="D">{paymentDetails.payer_name}</p>
              </b>
            </div>
            <div className="detail">
              <p>Gym Number</p>
              <b>
                <p className="D">{paymentDetails.user_id}</p>
              </b>
            </div>
            <div className="detail">
              <p>Payment Method</p>
              <b>
                <p className="D">{paymentDetails.payment_method}</p>
              </b>
            </div>
            <div className="detail">
              <p>Date of Payment</p>
              <b>
                <p className="D">{paymentDetails.date}</p>
              </b>
            </div>
            <div className="detail">
              <p>Amount Paid</p>
              <b>
                <p className="D">₦{paymentDetails.amount}</p>
              </b>
            </div>
          </div>
        )}
        <div className="modal-button">
          <button type="button" onClick={() => setNotifDecline(false)}>
            Back
          </button>
          <button
            type="button"
            onClick={() => setViewModal2(true) & setNotifDecline(false)}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Decline
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={viewModal}
        onRequestClose={() => setViewModal(false)}
        contentLabel="Approve payment verification"
        className="view-staff-modal"
      >
        <div>
          <div className="enter-password">
            <h2>Enter Password</h2>
          </div>
          <p>Enter password to approve payment</p>
          <form className="view-staff-form" onSubmit={handleApprovePayment}>
            <label htmlFor="password">Password:</label>
            <br />
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="modal-button">
              <button type="button" onClick={() => setViewModal(false)}>
                Back
              </button>
              <button type="submit">Complete</button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={viewModal2}
        onRequestClose={() => setViewModal2(false)}
        contentLabel="Decline payment verification"
        className="view-staff-modal"
      >
        <div>
          <div className="enter-password">
            <h2>Enter Password</h2>
          </div>
          <p>Enter password to decline payment</p>
          <form className="view-staff-form" onSubmit={handleDeclinePayment}>
            <label htmlFor="password">Password:</label>
            <br />
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="modal-button">
              <button type="button" onClick={() => setViewModal2(false)}>
                Back
              </button>
              <button type="submit">Complete</button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={approveSuccess}
        onRequestClose={() => setApproveSuccess(false)}
        contentLabel="Approve Success"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "bold" }}>Payment Approved</h2>
        <button
          onClick={() => {
            setApproveSuccess(false);
            window.location.reload(); // This will refresh the page
          }}
        >
          Close
        </button>
      </Modal>

      <Modal
        isOpen={declineSuccess}
        onRequestClose={() => setDeclineSuccess(false)}
        contentLabel="Decline Success"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "bold" }}>Payment Declined</h2>
        <button
          onClick={() => {
            setDeclineSuccess(false);
            window.location.reload(); // This will refresh the page
          }}
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

export default Notifications;
