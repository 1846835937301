import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./components/login";
import RequestOTP from "./components/requestOtp";
import Dashboard from "./components/dashboard";
import ResetPassword from "./components/ResetPassword";
import MemberManagement from "./components/memberManagement";
import StaffManagement from "./components/staffManagement";
import Notifications from "./components/notifications";
import Subscriptions from "./components/subscriptions";
import Settings from "./components/settings";
import InputOTP from "./components/inputOtp";
import QrCode from "./components/qrCode";
import ProtectedRoute from "./components/ProtectedRoute";
import ViewMember from "./components/ViewMember";
import { MemberProvider } from "./MemberContext";
import successSound from "./sounds/success.mp3";
import errorSound from "./sounds/error.mp3";
import silentSound from "./sounds/silence.mp3";
import { io } from "socket.io-client";
import Modal from "react-modal";

// Initialize socket connection
const socket = io("https://broadcast.frebsongym.com");

const silentAudio = new Audio(silentSound);

function App() {
  return (
    <Router>
      <MemberProvider>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/request-otp" element={<RequestOTP />} />
            <Route path="/input-otp" element={<InputOTP />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/member-management"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <MemberManagement />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/staff-management"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <StaffManagement />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <Notifications />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/qr-code"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <QrCode />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <Subscriptions />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <Settings />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-member"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <ViewMember />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </MemberProvider>
    </Router>
  );
}

function MainLayout({ children }) {
  const [scanSuccessisOpen, setScanSuceessIsOpen] = useState(false);
  const [scanErrorisOpen, setScanErrorIsOpen] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [userId, setUserId] = useState("");
  const [hasInteracted, setHasInteracted] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true); // Track if the tab is active
  const location = useLocation(); // Now this will work since it's inside Router

  const handleUserInteraction = () => {
    setHasInteracted(true);
  };

  useEffect(() => {
    window.addEventListener("click", handleUserInteraction);
    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    if (hasInteracted) {
      silentAudio.play();
    }
  }, [hasInteracted]);

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsTabActive(true); // Tab is active
      } else {
        setIsTabActive(false); // Tab is inactive
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const playSuccessSound = () => {
    if (hasInteracted) {
      const audio = new Audio(successSound);
      audio.play();
      console.log("played");
    }
  };

  const playErrorSound = () => {
    if (hasInteracted) {
      const audio = new Audio(errorSound);
      audio.play();
      console.log("played");
    }
  };

  // Routes where we don't want the socket to work
  const excludedRoutes = [
    "/login",
    "/request-otp",
    "/input-otp",
    "/reset-password",
  ];

  useEffect(() => {
    // Only activate the socket if the current route is not excluded AND the tab is active
    if (!excludedRoutes.includes(location.pathname) && isTabActive) {
      socket.on("clock-in-notify", (message) => {
        setFname(message.user.f_name);
        setLname(message.user.l_name);
        setProfileImage(message.user.profile_image);
        setUserId(message.user.id);

        if (message.type === "success") {
          playSuccessSound();
          setScanSuceessIsOpen(true);

          // Automatically close after 5 seconds
          setTimeout(() => {
            setScanSuceessIsOpen(false);
          }, 5000);
        } else if (message.type === "error") {
          playErrorSound();
          setScanErrorIsOpen(true);

          // Automatically close after 5 seconds
          setTimeout(() => {
            setScanErrorIsOpen(false);
          }, 5000);
        }
      });
    }

    return () => {
      socket.off("clock-in-notify"); // Clean up the socket listener
    };
  }, [hasInteracted, location.pathname, isTabActive]); // Include isTabActive in the dependency array

  return (
    <>
      {children}
      {/* Modals for QR code notifications */}
      {!excludedRoutes.includes(location.pathname) && (
        <>
          <Modal
            isOpen={scanSuccessisOpen}
            onRequestClose={() => setScanSuceessIsOpen(false)}
            contentLabel="Enable Success Modal"
            className="edit-success-modal"
          >
            <div className="modal-content">
              <img
                src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`}
                alt=""
              />
              <h2>Access Granted</h2>
              <div className="qr-notif-box">
                <img
                  src={
                    profileImage
                      ? profileImage
                      : `${process.env.PUBLIC_URL}/images/settings-avatar.png`
                  }
                  alt="Profile"
                />
                <div className="qr-notif-details">
                  <p>
                    <span className="notif-span">Name:</span> {fname} {lname}
                  </p>
                  <p>
                    <span className="notif-span">Gym Number:</span> #{userId}
                  </p>
                </div>
              </div>
            </div>
            <button onClick={() => setScanSuceessIsOpen(false)}>close</button>
          </Modal>

          <Modal
            isOpen={scanErrorisOpen}
            onRequestClose={() => setScanErrorIsOpen(false)}
            contentLabel="Enable Error Modal"
            className="edit-success-modal"
          >
            <div className="modal-content">
              <img
                src={`${process.env.PUBLIC_URL}/images/delete-danger.png`}
                alt=""
              />
              <h2>Access Denied</h2>
              <div className="qr-notif-box">
                <img
                  src={
                    profileImage
                      ? profileImage
                      : `${process.env.PUBLIC_URL}/images/settings-avatar.png`
                  }
                  alt="Profile"
                />
                <div className="qr-notif-details">
                  <p>
                    <span className="notif-span">Name:</span> {fname} {lname}
                  </p>
                  <p>
                    <span className="notif-span">Gym Number:</span> #{userId}
                  </p>
                </div>
              </div>
            </div>
            <button onClick={() => setScanErrorIsOpen(false)}>Close</button>
          </Modal>
        </>
      )}
    </>
  );
}

export default App;
