import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  createRef,
} from "react";
import Modal from "react-modal";
import Sidebar from "./Sidebar";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Parser } from "json2csv";
import { MemberContext } from "../MemberContext";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

function MemberManagement() {
  const currentDate = useMemo(() => new Date(), []);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const MonthsOfYear = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );
  const formattedDate = `${daysOfWeek[currentDate.getDay()]}, ${
    MonthsOfYear[currentDate.getMonth()]
  } ${currentDate.getDate()}`;
  const adminData = sessionStorage.getItem("adminName");
  const [allUsers, setAllUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useMemo(() => localStorage.getItem("accessToken"), []);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [optionsPopupOpen, setOptionsPopupOpen] = useState(null);
  const [addMember, setAddMember] = useState(false);
  const [step, setStep] = useState(1);
  const [profileImage, setProfileImage] = useState(
    `${process.env.PUBLIC_URL}/images/upload-img.png`
  );
  const popupRef = createRef();
  const [memberData, setMemberData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    dateOfBirth: "",
    profession: "",
    NIN: "",
    startWeight: "",
    startHeight: "",
    bodySize: "",
    profileImage: `${process.env.PUBLIC_URL}/images/upload-img.png`,
  });
  const [errors, setErrors] = useState({
    phoneNumber: "",
    NIN: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Allow only numbers and limit to 11 digits for phoneNumber and NIN
    if (name === "phoneNumber" || name === "NIN") {
      if (/^\d{0,11}$/.test(value)) {
        setMemberData({ ...memberData, [name]: value });

        // Validate Phone Number
        if (name === "phoneNumber" && value.length !== 11) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber: "Phone number must be exactly 11 digits",
          }));
        } else if (name === "phoneNumber") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber: "",
          }));
        }

        // Validate NIN
        if (name === "NIN" && value.length !== 11) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            NIN: "NIN must be 11 exactly digits",
          }));
        } else if (name === "NIN") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            NIN: "",
          }));
        }
      }
    } else {
      // Handle other input changes
      setMemberData({ ...memberData, [name]: value });
    }
  };

  const handleDateChange = (date, name) =>
    setMemberData({ ...memberData, [name]: date });
  const handleNext = () => {
    let isValid = true;

    if (step === 1) {
      // Check if all required fields in step 1 are filled
      isValid =
        memberData.firstName &&
        memberData.lastName &&
        memberData.phoneNumber &&
        memberData.email &&
        memberData.address &&
        memberData.profession;

      if (!isValid) {
        alert("Please fill out all fields in Step 1.");
        return;
      }
    } else if (step === 2) {
      // Check if profile image is present
      if (
        !memberData.profileImage ||
        memberData.profileImage ===
          `${process.env.PUBLIC_URL}/images/upload-img.png`
      ) {
        alert("Profile image is required.");
        return; // Exit if profile image is missing
      }

      // Check if all required fields in step 2 are filled
      isValid = memberData.startWeight && memberData.startHeight; // Only check for mandatory fields

      // Optional: you can still check bodySize, but it's not required
      if (!isValid) {
        alert("Please fill out all fields in Step 2.");
        return;
      }
    }

    // Move to the next step if validation is successful
    if (isValid) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => setStep((prevStep) => prevStep - 1);
  const handleOpenModal = () => setAddMember(true);
  const handleCloseModal = () => {
    setAddMember(false);
    setStep(1);
    setMemberData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: "",
      dateOfBirth: "",
      profession: "",
      NIN: "",
      startWeight: "",
      startHeight: "",
      bodySize: "",
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;
      const validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/gif",
        "image/svg+xml",
      ];

      if (!validFileTypes.includes(fileType)) {
        alert(
          "Invalid file type. Please upload an image of type: jpeg, png, jpg, gif, svg."
        );
        return;
      }

      if (fileSize > 4 * 1024 * 1024) {
        alert("File size too large. The file must not be greater than 4mb.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setMemberData({ ...memberData, profileImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: "/super-admin",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    [token]
  );

  const fetchMembers = async (page) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/get-customers?page=${page}`);
      setMembers(response.data.message.data);
      setTotalPages(response.data.message.last_page);
    } catch (error) {
      console.error(
        "Error fetching members:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers(currentPage);
  }, [currentPage, axiosInstance]);

  const fetchSearchResults = async (searchTerm) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/search-customers?value=${searchTerm}`
      );
      setMembers(response.data.message.users.data);
      setTotalPages(response.data.message.users.last_page);
    } catch (error) {
      console.error(
        "Error searching members:",
        error.response ? error.response.data : error.message
      );
      setMembers([]);
    } finally {
      setLoading(false);
    }
  };

  // Handle the effect for initial loading and search term updates
  useEffect(() => {
    if (searchTerm === "") {
      fetchMembers(currentPage); // Fetch all customers if no search term
    } else {
      fetchSearchResults(searchTerm, currentPage); // Fetch search results if there is a search term
    }
  }, [currentPage, searchTerm]);

  const mapAccessStatus = (status) =>
    status === 1 ? "Enabled" : status === 0 ? "Disabled" : "Unknown";

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      fetchMembers(pageNumber);
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term
    setCurrentPage(1); // Reset to the first page when a new search is made
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const range = 1; // Number of pages to show before and after the current page

    // If we're on the first page (after search term change)
    if (currentPage === 1 && searchTerm !== "") {
      pageNumbers.push(1); // Only show the first page
    }
    // If we're on the first page, show the first few pages (2, 3) and the last page with ellipses
    else if (currentPage === 1) {
      pageNumbers.push(1);
      for (let i = 2; i <= 3; i++) {
        pageNumbers.push(i);
      }
      if (totalPages > 3) pageNumbers.push("...");
      pageNumbers.push(totalPages); // Show the last page
    }
    // If current page is far from the first page, show a gap with ellipses
    else if (currentPage > range + 1 && currentPage < totalPages - range) {
      pageNumbers.push(1);
      pageNumbers.push("...");
      for (let i = currentPage - range; i <= currentPage + range; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...");
      pageNumbers.push(totalPages); // Always show the last page
    }
    // If we're at the start, show pages from 2 to 3 (up to 2 pages after page 1)
    else if (currentPage <= range + 1) {
      pageNumbers.push(1);
      for (let i = 2; i <= range + 2; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }
    // If we're near the last pages, show pages up to the last page with ellipses
    else if (currentPage >= totalPages - range) {
      pageNumbers.push(1);
      pageNumbers.push("...");
      for (let i = totalPages - range - 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handleExportCSV = () => {
    if (members.length === 0) {
      alert("No data available to export");
      return;
    }
    const fields = [
      "id",
      "f_name",
      "l_name",
      "average_gym_hours",
      "total_gym_hours",
      "access_status",
      "subscription_status",
    ];
    const opts = { fields };
    const parser = new Parser(opts);
    const csv = parser.parse(members);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "members.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleExportCSVWithFetch = async () => {
    try {
      // Step 1: Fetch all users
      const response = await axiosInstance.get("/get-all-users");
      const memberData = response.data.message; // Assuming message contains the array of users

      if (memberData.length === 0) {
        alert("No data available to export");
        return;
      }

      // Step 2: Define the fields for the CSV
      const fields = [
        "id",
        "f_name",
        "l_name",
        "average_gym_hours",
        "total_gym_hours",
        "access_status",
        "subscription_status",
      ];
      const opts = { fields };

      // Step 3: Parse the fetched data into CSV format
      const parser = new Parser(opts);
      const csv = parser.parse(memberData);

      // Step 4: Create a Blob from the CSV string and trigger a download
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "members.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Cleanup
    } catch (error) {
      console.error("Error fetching users or exporting CSV:", error);
      alert("An error occurred while exporting the data.");
    }
  };

  const openOptionsPopup = (index) =>
    setOptionsPopupOpen(optionsPopupOpen === index ? null : index);

  const getSubscriptionStatusColor = (status) =>
    status === "subscribed"
      ? "green"
      : status === "expired"
      ? "red"
      : status === "inactive"
      ? "orange"
      : "#737373";

  const handleComplete = async () => {
    // Check for required fields
    if (
      !memberData.profileImage ||
      memberData.profileImage ===
        `${process.env.PUBLIC_URL}/images/upload-img.png`
    ) {
      alert("Profile image is required.");
      return; // Exit the function if the profile image is missing
    }

    // Format the date of birth
    const formattedDob = formatDate(memberData.dateOfBirth);

    // Create FormData object to send the data including the image
    const formData = new FormData();
    formData.append("f_name", memberData.firstName);
    formData.append("l_name", memberData.lastName);
    formData.append("phone", memberData.phoneNumber);
    formData.append("email", memberData.email);
    formData.append("address", memberData.address);

    // Append DOB only if it exists
    if (memberData.dateOfBirth) {
      formData.append("dob", formattedDob);
    }

    // Append NIN only if it exists
    if (memberData.NIN) {
      formData.append("nin", memberData.NIN);
    }

    formData.append("profession", memberData.profession);
    formData.append("weight", memberData.startWeight);
    formData.append("height", memberData.startHeight);

    // Append body size only if it exists
    if (memberData.bodySize) {
      formData.append("body_size", memberData.bodySize);
    }

    // Append profile image
    formData.append(
      "profile_image",
      dataURLtoFile(memberData.profileImage, "profile_image.png")
    );

    try {
      const response = await axiosInstance.post(
        "/create-customer-account",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      handleCloseModal(); // Close the modal after success
      setCompleteSuccess(true); // Indicate success
    } catch (error) {
      // Handle the error and display an alert with the message
      let errorMessage = "Something went wrong while submitting the form.";

      // Check if there's a structured error in error.response.data.error
      if (error.response && error.response.data && error.response.data.errors) {
        const errorfield = error.response.data.errors;

        // Collect multiple field error messages
        const fieldErrors = Object.keys(errorfield)
          .map((field) => `${field}: ${errorfield[field]}`) // Format "field: errorMessage"
          .join("\n"); // Join all error messages with new lines

        errorMessage = `Error creating member:\n${fieldErrors}`;
      } else if (error.message) {
        errorMessage = error.message;
      }

      // Display the error message using an alert
      alert(errorMessage);
      console.error("Error submitting form:", errorMessage);
    }
  };

  // Utility function to convert base64/URL to file
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  // Date formatting function
  const formatDate = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Months are zero based
    const year = d.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${day}-${month}-${year}`;
  };

  const [selectedMember, setSelectedMember] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDisableModalOpen, setDisableModalOpen] = useState(false);
  const [isEnableModalOpen, setEnableModalOpen] = useState(false);
  const [completeSuccess, setCompleteSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [disableSuccess, setDisableSuccess] = useState(false);
  const [enableSuccess, setEnableSuccess] = useState(false);

  const handleOpenDeleteModal = (member) => {
    setSelectedMember(member);
    setDeleteModalOpen(true);
    setOptionsPopupOpen(false);
  };

  const handleOpenDisableModal = (member) => {
    setSelectedMember(member);
    setDisableModalOpen(true);
    setOptionsPopupOpen(false);
  };

  const handleOpenEnableModal = (member) => {
    setSelectedMember(member);
    setEnableModalOpen(true);
    setOptionsPopupOpen(false);
  };

  const handleCloseModals = () => {
    setDeleteModalOpen(false);
    setDisableModalOpen(false);
    setEnableModalOpen(false);
    setSelectedMember(null);
    setOptionsPopupOpen(false);
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axiosInstance.post("/delete-user", {
        user_id: selectedMember.id,
      });
      setMembers((prevMembers) =>
        prevMembers.filter((member) => member.id !== selectedMember.id)
      );
      handleCloseModals();
      setDeleteSuccess(true);
    } catch (error) {
      alert(error.response.data.message);
      console.error(
        "Error deleting user:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleDisableUser = async () => {
    try {
      const response = await axiosInstance.post("/disable-user-access", {
        user_id: selectedMember.id,
      });
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === selectedMember.id
            ? { ...member, access_status: 0 }
            : member
        )
      );
      handleCloseModals();
      setDisableSuccess(true);
    } catch (error) {
      alert(error.response.data.message);
      console.error(
        "Error disabling user:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleEnableUser = async () => {
    try {
      const response = await axiosInstance.post("/enable-user-access", {
        user_id: selectedMember.id,
      });
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === selectedMember.id
            ? { ...member, access_status: 1 }
            : member
        )
      );
      handleCloseModals();
      setEnableSuccess(true);
    } catch (error) {
      console.error(
        "Error enabling user:",
        error.response ? error.response.data : error.message
      );
      alert(error.response.data.message);
    }
  };

  const navigate = useNavigate();
  const { setSelectedMemberId } = useContext(MemberContext);

  const handleViewProfile = (memberId) => {
    setSelectedMemberId(memberId); // Set the selected member ID in the context
    navigate("/view-member");
  };

  const closeOptionsPopup = () => {
    setOptionsPopupOpen(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeOptionsPopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <div className="dashboard container-fluid">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display container-fluid">
        <div className="top-bar container-fluid">
          <div className="top-bar-info">
            <p id="date">{formattedDate}</p>
            <b>
              <p>Welcome back {adminData}</p>
            </b>
          </div>
          <div className="top-bar-button">
            <button onClick={handleOpenModal}>
              <img
                src={`${process.env.PUBLIC_URL}/images/add-user.png`}
                alt=""
                style={{ height: "15px" }}
              />{" "}
              Add Member
            </button>
          </div>
        </div>
        <div className="main-dashboard container-fluid sub-dashboard">
          <div
            style={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
            className="main-settings staff-items"
          >
            <div className="search">
              <input
                type="text"
                placeholder="Search fields"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="export-group">
              <div className="export">
                <button onClick={handleExportCSVWithFetch}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/export-icon.png`}
                    alt=""
                  />{" "}
                  Export all
                </button>
              </div>

              <div className="export">
                <button onClick={handleExportCSV}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/export-icon.png`}
                    alt=""
                  />{" "}
                  Export Page
                </button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "0" }} className="show-admin-table">
            <table className="admin-table member-table">
              <thead>
                <tr>
                  <th>Gym Number</th>
                  <th>Name</th>
                  <th>Total Gym Hours</th>
                  <th>Access Status</th>
                  <th>Subscription Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7">
                      <div className="spinner"></div>
                    </td>
                  </tr>
                ) : members.length > 0 ? (
                  members.map((member) => (
                    <tr key={member.id}>
                      <td>{member.id}</td>
                      <td>
                        {member.f_name} {member.l_name}
                      </td>
                      <td>{member.total_gym_hours || "N/A"}</td>
                      <td>{mapAccessStatus(member.access_status)}</td>
                      <td
                        style={{
                          color: getSubscriptionStatusColor(
                            member.subscription_status
                          ),
                          fontWeight: "700",
                        }}
                      >
                        {member.subscription_status || "N/A"}
                      </td>
                      <td>
                        <div className="options-menu">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/horizontal-menu.png`}
                            onClick={() => openOptionsPopup(member.id)}
                          />
                          {optionsPopupOpen === member.id && (
                            <div className="popup" ref={popupRef}>
                              <ul>
                                <li
                                  style={{ color: "#0473BD" }}
                                  onClick={() => handleViewProfile(member.id)}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/eye.png`}
                                  />
                                  View Profile
                                </li>
                                {member.access_status === 0 && (
                                  <li
                                    onClick={() =>
                                      handleOpenEnableModal(member)
                                    }
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/enable-user.png`}
                                    />
                                    Enable Access
                                  </li>
                                )}
                                {member.access_status === 1 && (
                                  <li
                                    onClick={() =>
                                      handleOpenDisableModal(member)
                                    }
                                    style={{ color: "#DD9828" }}
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/disable-user.png`}
                                    />
                                    Disable Access
                                  </li>
                                )}
                                <li
                                  onClick={() => handleOpenDeleteModal(member)}
                                  style={{ color: "red" }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/staff-delete.png`}
                                  />
                                  Delete Profile
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No members found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination staff-pagination">
  <div>
    {/* Previous button */}
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1 || loading}
      aria-label="Previous page"
    >
      {loading ? (
        <span className="loading-spinner" aria-hidden="true">⏳</span> // Spinner during loading
      ) : (
        "<" // Left arrow for previous
      )}
    </button>

    {/* Display page numbers */}
    {generatePageNumbers().map((page, index) => (
      <button
        key={index}
        onClick={() => {
          if (page !== "...") {
            handlePageChange(page); // Go to the clicked page
          }
        }}
        className={page === currentPage ? "active" : ""}
        disabled={page === "..." || loading}
        style={{ width: "30px" }}
      >
        {page}
      </button>
    ))}

    {/* Next button */}
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages || loading}
      aria-label="Next page"
    >
      {loading ? (
        <span className="loading-spinner" aria-hidden="true">⏳</span> // Spinner during loading
      ) : (
        ">" // Right arrow for next
      )}
    </button>
  </div>
</div>

          </div>
        </div>
      </div>
      <Modal
        isOpen={addMember}
        onRequestClose={handleCloseModal}
        className="add-admin-modal container-fluid"
      >
        <div className="form-container">
          {step === 1 && (
            <div className="inner-form">
              <div className="add-staff-head">
                <h2>Member Details</h2>
                <div className="form-step-indicator">
                  <p>Page {step} of 3</p>
                  <div className="step-indicator-container">
                    <span
                      className={`step ${
                        step === 1
                          ? "active"
                          : step > 1
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                    <span className="divider"></span>
                    <span
                      className={`step ${
                        step === 2
                          ? "active"
                          : step > 2
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                    <span className="divider"></span>
                    <span
                      className={`step ${
                        step === 3
                          ? "active"
                          : step > 3
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="add-staff-image">
                <div className="add-staff-image-pic">
                  <img
                    src={profileImage}
                    alt="Profile Preview"
                    width="36px"
                    height="36px"
                  />
                  <div className="add-staff-image-text">
                    <p>Upload member's picture</p>
                    <p>PNG OR JPG</p>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/png, image/jpeg image/jpg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="upload-picture"
                />
                <label htmlFor="upload-picture" style={{ cursor: "pointer" }}>
                  Upload picture
                </label>
              </div>
              <div className="form-input">
                <div className="member-name">
                  <div id="member-fname">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      id="firstName"
                      name="firstName"
                      value={memberData.firstName}
                      onChange={handleInputChange}
                      required
                      type="text"
                    />
                  </div>
                  <div id="member-lname">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      id="lastName"
                      name="lastName"
                      value={memberData.lastName}
                      onChange={handleInputChange}
                      required
                      type="text"
                    />
                  </div>
                </div>
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={memberData.phoneNumber}
                  onChange={handleInputChange}
                  required
                  type="text"
                  maxLength="11"
                  style={{
                    borderColor: errors.phoneNumber ? "red" : "initial",
                    marginBottom: "0",
                  }}
                />
                {errors.phoneNumber && (
                  <small
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginBottom: "2px",
                    }}
                  >
                    {errors.phoneNumber}
                  </small>
                )}
                <br />
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  value={memberData.email}
                  onChange={handleInputChange}
                  required
                  type="email"
                />
                <label htmlFor="address">Address</label>
                <input
                  id="address"
                  name="address"
                  value={memberData.address}
                  onChange={handleInputChange}
                  required
                  type="text"
                />
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <div className="date-picker">
                  <DatePicker
                    selected={memberData.dateOfBirth}
                    onChange={(date) => handleDateChange(date, "dateOfBirth")}
                    showPopperArrow={false}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select a date"
                  />
                </div>
                <label htmlFor="profession">Profession</label>
                <input
                  id="profession"
                  name="profession"
                  value={memberData.profession}
                  onChange={handleInputChange}
                  required
                  type="text"
                />
                <label htmlFor="NIN">
                  National Identification Number (NIN)
                </label>
                <input
                  id="NIN"
                  name="NIN"
                  value={memberData.NIN}
                  onChange={handleInputChange}
                  required
                  type="text"
                  maxLength="11"
                  style={{
                    borderColor: errors.NIN ? "red" : "initial",
                    marginBottom: "0",
                  }}
                />
                {errors.NIN && (
                  <small
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginBottom: "2px",
                    }}
                  >
                    {errors.NIN}
                  </small>
                )}
              </div>
              <div className="modal-button">
                <button onClick={handleCloseModal}>Cancel</button>
                <button onClick={handleNext}>Next</button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="inner-form">
              <div className="add-staff-head">
                <h2>Member Details</h2>
                <div className="form-step-indicator">
                  <p>Page {step} of 3</p>
                  <div className="step-indicator-container">
                    <span
                      className={`step ${
                        step === 1
                          ? "active"
                          : step > 1
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                    <span className="divider"></span>
                    <span
                      className={`step ${
                        step === 2
                          ? "active"
                          : step > 2
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                    <span className="divider"></span>
                    <span
                      className={`step ${
                        step === 3
                          ? "active"
                          : step > 3
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="form-input">
                <label htmlFor="startWeight">Start Weight (kg)</label>
                <input
                  id="startWeight"
                  name="startWeight"
                  value={memberData.startWeight}
                  onChange={handleInputChange}
                  placeholder="weight in kg"
                  required
                  type="number"
                />
                <label htmlFor="startHeight">Start Height (cm)</label>
                <input
                  id="startHeight"
                  name="startHeight"
                  value={memberData.startHeight}
                  onChange={handleInputChange}
                  placeholder="height in cm"
                  required
                  type="number"
                />
                <label htmlFor="bodySize">Body Size</label>
                <input
                  id="bodySize"
                  name="bodySize"
                  value={memberData.bodySize}
                  onChange={handleInputChange}
                  placeholder="body size in digits"
                  required
                  type="number"
                />
              </div>
              <div className="modal-button">
                <button onClick={handlePrevious}>Back</button>
                <button onClick={handleNext}>Next</button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="inner-form">
              <div className="add-staff-head">
                <h2>Member Details</h2>
                <div className="form-step-indicator">
                  <p>Page {step} of 3</p>
                  <div className="step-indicator-container">
                    <span
                      className={`step ${
                        step === 1
                          ? "active"
                          : step > 1
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                    <span className="divider"></span>
                    <span
                      className={`step ${
                        step === 2
                          ? "active"
                          : step > 2
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                    <span className="divider"></span>
                    <span
                      className={`step ${
                        step === 3
                          ? "active"
                          : step > 3
                          ? "completed"
                          : "inactive"
                      }`}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="member-preview-head">
                <img
                  style={{ borderRadius: "50%" }}
                  src={memberData.profileImage}
                  alt="Profile Preview"
                  width="48px"
                  height="48px"
                />
                <p>
                  {memberData.firstName} {memberData.lastName}
                </p>
              </div>
              <p
                style={{
                  marginLeft: "23px",
                  marginBottom: "4px",
                  marginTop: "24px",
                  color: "#8C8C8C",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Personal Information
              </p>
              <div
                style={{ marginTop: "0" }}
                className="details-container container"
              >
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_dob.png`}
                      alt="dob"
                    />
                    <p>Date of Birth</p>
                  </span>
                  <b>
                    <p className="D">
                      {memberData.dateOfBirth
                        ? new Date(memberData.dateOfBirth).toLocaleDateString()
                        : ""}
                    </p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_address.png`}
                      alt="dob"
                    />
                    <p>Home Address</p>
                  </span>
                  <b>
                    <p className="D">{memberData.address}</p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_profession.png`}
                      alt="dob"
                    />
                    <p>Profession</p>
                  </span>
                  <b>
                    <p className="D">{memberData.profession}</p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_email.png`}
                      alt="dob"
                    />
                    <p>Email</p>
                  </span>
                  <b>
                    <p className="D">{memberData.email}</p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_mobile.png`}
                      alt="dob"
                    />
                    <p>Phone Number</p>
                  </span>
                  <b>
                    <p className="D">{memberData.phoneNumber}</p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_nin.png`}
                      alt="dob"
                    />
                    <p>NIN</p>
                  </span>
                  <b>
                    <p className="D">{memberData.NIN}</p>
                  </b>
                </div>
              </div>
              <p
                style={{
                  marginLeft: "23px",
                  marginBottom: "4px",
                  marginTop: "24px",
                  color: "#8C8C8C",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Gym Information
              </p>
              <div
                style={{ marginTop: "0" }}
                className="details-container container"
              >
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_weight.png`}
                      alt="dob"
                    />
                    <p>Start Weight</p>
                  </span>
                  <b>
                    <p className="D">{memberData.startWeight}kg</p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_nin.png`}
                      alt="dob"
                    />
                    <p>Start Height</p>
                  </span>
                  <b>
                    <p className="D">{memberData.startHeight}cm</p>
                  </b>
                </div>
                <div className="detail">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/user_size.png`}
                      alt="dob"
                    />
                    <p>Body Size</p>
                  </span>
                  <b>
                    <p className="D">Size {memberData.bodySize}</p>
                  </b>
                </div>
              </div>
              <div className="modal-button">
                <button onClick={handlePrevious}>Back</button>
                <button onClick={handleComplete}>Complete</button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={completeSuccess}
        onRequestClose={() => setCompleteSuccess(false)}
        contentLabel="Complete Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>Successful</h2>
        <p>Member has been added successfully</p>
        <button
          type="button"
          onClick={() => {
            setCompleteSuccess(false);
            window.location.reload();
          }}
        >
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={handleCloseModals}
        className="delete-modal"
      >
        {selectedMember && (
          <div className="inner-member">
            <img
              src={`${process.env.PUBLIC_URL}/images/delete-modal-img.png`}
              alt=""
            />
            <b>
              <p>
                Are you sure you want to disable user access for the selected
                user?
              </p>
            </b>
            <div className="details-container container">
              <div className="detail">
                <p>Name</p>
                <b>
                  <p className="D">
                    {selectedMember.l_name} {selectedMember.f_name}
                  </p>
                </b>
              </div>
              <div className="detail">
                <p>Gym Number</p>
                <b>
                  <p className="D">#{selectedMember.id}</p>
                </b>
              </div>
            </div>
            <div className="warning">
              <img src={`${process.env.PUBLIC_URL}/images/info-circle.png`} />
              <p>
                Deleting the user profile will permanently remove all associated
                data, including membership details, attendance records, and any
                other information linked to this profile.
              </p>
            </div>
            <div className="inner-member-btn">
              <button onClick={handleCloseModals}>Cancel</button>
              <button
                style={{ backgroundColor: "red", color: "#FAFAFA" }}
                onClick={handleDeleteUser}
              >
                Delete Profile
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={deleteSuccess}
        onRequestClose={() => setDeleteSuccess(false)}
        contentLabel="Delete Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>User Profile Deleted</h2>
        <button type="button" onClick={() => setDeleteSuccess(false)}>
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={isDisableModalOpen}
        onRequestClose={handleCloseModals}
        className="delete-modal"
      >
        {selectedMember && (
          <div className="inner-member">
            <img
              src={`${process.env.PUBLIC_URL}/images/disable-modal-img.png`}
              alt=""
            />
            <b>
              <p>
                Are you sure you want to disable user access for the selected
                user?
              </p>
            </b>
            <div className="details-container container">
              <div className="detail">
                <p>Name</p>
                <b>
                  <p className="D">
                    {selectedMember.l_name} {selectedMember.f_name}
                  </p>
                </b>
              </div>
              <div className="detail">
                <p>Gym Number</p>
                <b>
                  <p className="D">#{selectedMember.id}</p>
                </b>
              </div>
              <div className="detail">
                <p>Access Status</p>
                <b>
                  <p className="D">
                    {selectedMember.access_status === 1
                      ? "Enabled"
                      : "Disabled"}
                  </p>
                </b>
              </div>
            </div>
            <div className="warning">
              <img src={`${process.env.PUBLIC_URL}/images/info-circle.png`} />
              <p>
                Note that disabling user access will prevent the member from
                entering the gym premises and using any associated services.
              </p>
            </div>
            <div className="inner-member-btn">
              <button onClick={handleCloseModals}>Cancel</button>
              <button
                style={{ backgroundColor: "#DD9828", color: "#FAFAFA" }}
                onClick={handleDisableUser}
              >
                Disable access
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={disableSuccess}
        onRequestClose={() => setDisableSuccess(false)}
        contentLabel="Disable Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>User Profile Disabled</h2>
        <button type="button" onClick={() => setDisableSuccess(false)}>
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={isEnableModalOpen}
        onRequestClose={handleCloseModals}
        className="delete-modal"
      >
        {selectedMember && (
          <div className="inner-member">
            <img
              src={`${process.env.PUBLIC_URL}/images/enable-modal-img.png`}
              alt=""
            />
            <b>
              <p>
                Are you sure you want to enable user access for the selected
                user?
              </p>
            </b>
            <div className="details-container container">
              <div className="detail">
                <p>Name</p>
                <b>
                  <p className="D">
                    {selectedMember.l_name} {selectedMember.f_name}
                  </p>
                </b>
              </div>
              <div className="detail">
                <p>Gym Number</p>
                <b>
                  <p className="D">#{selectedMember.id}</p>
                </b>
              </div>
              <div className="detail">
                <p>Access Status</p>
                <b>
                  <p className="D">
                    {selectedMember.access_status === 1
                      ? "Enabled"
                      : "Disabled"}
                  </p>
                </b>
              </div>
            </div>
            <div className="warning">
              <img src={`${process.env.PUBLIC_URL}/images/info-circle.png`} />
              <p>
                Note that enabling user access will allow the user to have
                access to the gym premises or any associated services.
              </p>
            </div>
            <div className="inner-member-btn">
              <button onClick={handleCloseModals}>Cancel</button>
              <button
                style={{ backgroundColor: "#34B159", color: "#FAFAFA" }}
                onClick={handleEnableUser}
              >
                Grant access
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={enableSuccess}
        onRequestClose={() => setEnableSuccess(false)}
        contentLabel="Enable Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>Access Granted</h2>
        <button type="button" onClick={() => setEnableSuccess(false)}>
          Cancel
        </button>
      </Modal>
    </div>
  );
}

export default MemberManagement;
