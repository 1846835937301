import React, { useState, useMemo, useEffect, useContext } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { MemberContext } from "../MemberContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format, parseISO } from "date-fns";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional for styling
import Pagination from "./pagination";

function ViewMember() {
  const currentDate = useMemo(() => new Date(), []);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const MonthsOfYear = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );
  const formattedDate = `${daysOfWeek[currentDate.getDay()]}, ${
    MonthsOfYear[currentDate.getMonth()]
  } ${currentDate.getDate()}`;
  const { selectedMemberId, setSelectedMemberId } = useContext(MemberContext);
  const [userProfile, setUserProfile] = useState({});
  const token = useMemo(() => localStorage.getItem("accessToken"), []);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [attendanceHistory, setAttendanceHistory] = useState([]);
  const [attendanceCount, setAttendanceCount] = useState([]);
  const itemsPerPage = 8;
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  const [memberData, setMemberData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    dateOfBirth: null,
    profession: "",
    NIN: "",
    weight: "",
    height: "",
    bodySize: "",
  });

  const dataURLtoFile = (dataurl, filename) => {
    if (!dataurl) return null; // Handle empty or null data URL

    const arr = dataurl.split(",");
    if (arr.length < 2) return null; // Validate data URL format

    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const [profileImage, setProfileImage] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditModal2Open, setIsEditModal2Open] = useState(false);
  const [isDisableModalOpen, setDisableModalOpen] = useState(false);
  const [isEnableModalOpen, setEnableModalOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [disableSuccess, setDisableSuccess] = useState(false);
  const [EnableSuccess, setEnableSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [edit2Success, setEdit2Success] = useState(false);
  const [loading, setLoading] = useState(true);

  const formatDate = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Months are zero based
    const year = d.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${day}-${month}-${year}`;
  };

  const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: "/super-admin",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    [token]
  );

  const fetchMemberData = async (memberId) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post("/get-user-details", {
        user_id: memberId,
      });
      setUserProfile(response.data.message.user);
      setSubscriptionHistory(response.data.message.user.subscription_history);
      setAttendanceHistory(response.data.message.user.attendance_history);
      setAttendanceCount(response.data.message.user.attendance_history.length);
      setMemberData({
        firstName: response.data.message.user.f_name || "",
        lastName: response.data.message.user.l_name || "",
        phoneNumber: response.data.message.user.phone || "",
        email: response.data.message.user.email || "",
        address: response.data.message.user.address || "",
        dateOfBirth: response.data.message.user.dob
          ? parseDateString(response.data.message.user.dob)
          : null,
        profession: response.data.message.user.profession || "",
        NIN: response.data.message.user.nin || "",
        weight: response.data.message.user.start_weight || "",
        height: response.data.message.user.start_height || "",
        bodySize: response.data.message.user.body_size || "",
      });
      setProfileImage(response.data.message.user.profile_image || null);
    } catch (error) {
      console.error(
        "Error fetching members:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMemberId) {
      localStorage.setItem("selectedMemberId", selectedMemberId);
    }
  }, [selectedMemberId]);

  useEffect(() => {
    const storedMemberId = localStorage.getItem("selectedMemberId");
    if (storedMemberId) {
      fetchMemberData(storedMemberId);
    } else if (selectedMemberId) {
      fetchMemberData(selectedMemberId);
    }
  }, [selectedMemberId, axiosInstance]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setMemberData((prevState) => ({
      ...prevState,
      dateOfBirth: date,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;
      const validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/gif",
        "image/svg+xml",
      ];

      // Validate file type
      if (!validFileTypes.includes(fileType)) {
        alert(
          "Invalid file type. Please upload an image of type: jpeg, png, jpg, gif, svg."
        );
        return;
      }

      // Validate file size (2 MB limit)
      if (fileSize > 2 * 1024 * 1024) {
        // 2 MB in bytes
        alert("File size too large. The file must not be greater than 2 MB.");
        return;
      }

      // Create a FileReader to read the file and convert it to Data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result;
        setProfileImage(dataURL);
        setMemberData((prevState) => ({
          ...prevState,
          profileImage: dataURL,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const memberId = localStorage.getItem("selectedMemberId");

    // Create FormData object
    const formData = new FormData();
    formData.append("user_id", memberId);
    formData.append("f_name", memberData.firstName);
    formData.append("l_name", memberData.lastName);
    formData.append("phone", memberData.phoneNumber);
    formData.append("email", memberData.email);
    formData.append("address", memberData.address);
    formData.append(
      "dob",
      memberData.dateOfBirth ? formatDate(memberData.dateOfBirth) : null
    );
    formData.append("profession", memberData.profession);
    formData.append("nin", memberData.NIN);

    // Convert Data URL to File and append
    if (profileImage) {
      const profileImageFile = dataURLtoFile(profileImage, "profile-image.png");
      if (profileImageFile) {
        formData.append("profile_image", profileImageFile);
      }
    }

    try {
      const response = await axiosInstance.post(
        "/update-user-details",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the correct content type for file uploads
          },
        }
      );
      setEditSuccess(true);
      fetchMemberData(memberId); // Refresh data after save
      handleCloseModals();
    } catch (error) {
      console.error(
        "Error updating user details:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSave2 = async () => {
    const memberId = localStorage.getItem("selectedMemberId");

    const formData = new FormData();
    formData.append("user_id", memberId);
    formData.append("weight", memberData.weight);
    formData.append("height", memberData.height);
    formData.append("body_size", parseInt(memberData.bodySize)); // Convert to integer

    try {
      const response = await axiosInstance.post(
        "/update-user-stats",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEdit2Success(true);
      fetchMemberData(memberId); // Refresh data after save
      handleCloseModals();
      sessionStorage.removeItem("adminProfileData");
    } catch (error) {
      console.error(
        "Error updating user stats:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const totalPages = Math.ceil(subscriptionHistory.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subscriptionHistory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleCloseModals = () => {
    setDeleteModalOpen(false);
    setDisableModalOpen(false);
    setEnableModalOpen(false);
    setEditModalOpen(false);
    setEditSuccess(false);
    setIsEditModal2Open(false);
  };

  const handleDeleteUser = async () => {
    const storedMemberId = localStorage.getItem("selectedMemberId");
    try {
      const response = await axiosInstance.post("/delete-user", {
        user_id: storedMemberId,
      });
      handleCloseModals();
      setDeleteSuccess(true);
    } catch (error) {
      console.error(
        "Error deleting user:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleDisableUser = async () => {
    const storedMemberId = localStorage.getItem("selectedMemberId");
    try {
      const response = await axiosInstance.post("/disable-user-access", {
        user_id: storedMemberId,
      });
      handleCloseModals();
      setDisableSuccess(true);
    } catch (error) {
      console.error(
        "Error disabling user:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleEnableUser = async () => {
    const storedMemberId = localStorage.getItem("selectedMemberId");
    try {
      const response = await axiosInstance.post("/enable-user-access", {
        user_id: storedMemberId,
      });
      handleCloseModals();
      setEnableSuccess(true);
    } catch (error) {
      console.error(
        "Error Enabling user:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getHighlightedDates = (attendanceHistory) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to midnight to compare only dates
    const todayString = today.toLocaleDateString("en-CA"); // Get today's date in 'YYYY-MM-DD' format

    return attendanceHistory
      .map((attendance) => {
        const date = new Date(attendance.created_at);
        date.setHours(0, 0, 0, 0); // Set to midnight to compare only dates
        const formattedDate = date.toLocaleDateString("en-CA"); // Format date to 'YYYY-MM-DD'

        return formattedDate;
      })
      .filter((date) => date !== todayString); // Exclude today's date
  };

  const highlightedDates = useMemo(
    () => getHighlightedDates(attendanceHistory),
    [attendanceHistory]
  );

  // Custom tile content to add yellow circle on highlighted dates
  const tileClassName = ({ date }) => {
    const dateString = date.toLocaleDateString("en-CA");
    return highlightedDates.includes(dateString) ? "highlighted-date" : null;
  };

  const tileContent = ({ date }) => {
    const dateString = date.toLocaleDateString("en-CA");
    return highlightedDates.includes(dateString) ? (
      <span>{date.getDate()}</span>
    ) : null;
  };

  return (
    <div className="dashboard">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display">
        <div className="top-bar container-fluid">
          <div
            className="top-bar-info"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "auto",
            }}
          >
            <Link to="/member-management">
              <img
                src={`${process.env.PUBLIC_URL}/images/arrow-left.png`}
                alt="Back"
              />
            </Link>
            <p style={{ margin: 0, fontWeight: "bold" }}>User Profile</p>
          </div>
          <div className="top-bar-button user-profile-btn">
            {userProfile.access_status === 0 && (
              <button onClick={() => setEnableModalOpen(true)}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/enable-user.png`}
                  alt="Enable Access"
                />{" "}
                Enable Access
              </button>
            )}
            {userProfile.access_status === 1 && (
              <button onClick={() => setDisableModalOpen(true)}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/user-minus.png`}
                  alt=""
                />{" "}
                Disable Access
              </button>
            )}
            <button
              style={{ color: "red" }}
              onClick={() => setDeleteModalOpen(true)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/staff-delete.png`}
                alt=""
              />{" "}
              Delete Profile
            </button>
          </div>
        </div>
        <div className="user-profile-display">
          <div className="member-details">
            <div className="add-member-head">
              <h2>Member Details</h2>
              <button onClick={() => setEditModalOpen(true)}>
                Edit member details
              </button>
            </div>
            <div className="member-preview-head">
              {isLoading ? (
                <img
                  style={{ borderRadius: "50%", border: "1px solid #E6E6E6" }}
                  src={`${process.env.PUBLIC_URL}/images/settings-avatar.png`}
                  alt="Loading..."
                  width="48px"
                  height="48px"
                />
              ) : (
                <img
                  style={{ borderRadius: "50%", border: "1px solid #E6E6E6" }}
                  src={
                    userProfile.profile_image ||
                    `${process.env.PUBLIC_URL}/images/settings-avatar.png`
                  }
                  alt="Profile image"
                  width="48px"
                  height="48px"
                />
              )}
              <p>
                {isLoading
                  ? "..."
                  : `${userProfile.f_name || "N/A"} ${
                      userProfile.l_name || "N/A"
                    }`}
              </p>
            </div>
            <p
              style={{
                marginLeft: "23px",
                marginBottom: "4px",
                marginTop: "24px",
                color: "#8C8C8C",
                fontSize: "14px",
              }}
            >
              Personal Information
            </p>
            <div
              style={{ marginTop: "0" }}
              className="details-container container"
            >
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_dob.png`}
                    alt="dob"
                  />
                  <p>Date of Birth</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.dob || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_address.png`}
                    alt="Home Address"
                  />
                  <p style={{ minWidth: "100px" }}>Home Address</p>
                </span>
                <div className="address-container">
                  <Tippy content={userProfile.address}>
                    <p className="address-text">
                      {isLoading ? "..." : userProfile.address || "N/A"}
                    </p>
                  </Tippy>
                </div>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_profession.png`}
                    alt="dob"
                  />
                  <p>Profession</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.profession || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_email.png`}
                    alt="dob"
                  />
                  <p>Email</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.email || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_mobile.png`}
                    alt="dob"
                  />
                  <p>Phone Number</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.phone || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_nin.png`}
                    alt="dob"
                  />
                  <p>NIN</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.nin || "N/A"}
                </p>
              </div>
            </div>
            <p
              style={{
                marginLeft: "23px",
                marginBottom: "4px",
                marginTop: "24px",
                color: "#8C8C8C",
                fontSize: "14px",
              }}
            >
              Gym Information
            </p>
            <div
              style={{ marginTop: "0" }}
              className="details-container container"
            >
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_weight.png`}
                    alt="dob"
                  />
                  <p>Start Weight</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : `${userProfile.start_weight}kg` || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_nin.png`}
                    alt="dob"
                  />
                  <p>Start Height</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : `${userProfile.height}cm` || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_size.png`}
                    alt="dob"
                  />
                  <p>Body Size</p>
                </span>
                <p className="D">
                  {" "}
                  {isLoading ? "..." : `Size ${userProfile.body_size}` || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_sub_status.png`}
                    alt="dob"
                  />
                  <p>Subscription Status</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.subscription_status || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_sub_plan.png`}
                    alt="dob"
                  />
                  <p>Subscription Plan</p>
                </span>
                <p className="D">
                  {isLoading ? "..." : userProfile.subscription_plan || "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_sub_start.png`}
                    alt="dob"
                  />
                  <p>Subscription Start Date</p>
                </span>
                <p className="D">
                  {" "}
                  {isLoading
                    ? "..."
                    : userProfile.subscription_start_date
                    ? format(
                        parseISO(userProfile.subscription_start_date),
                        "dd MMM yyyy"
                      )
                    : "N/A"}
                </p>
              </div>
              <div className="detail">
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/user_sub_end.png`}
                    alt="dob"
                  />
                  <p>Subscription End Date</p>
                </span>
                <p className="D">
                  {" "}
                  {isLoading
                    ? "..."
                    : userProfile.subscription_due_date
                    ? format(
                        parseISO(userProfile.subscription_due_date),
                        "dd MMM yyyy"
                      )
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="gym-information">
            <div className="add-member-head">
              <h2>Member Gym Attendance</h2>
              <button onClick={() => setIsEditModal2Open(true)}>
                Update member stats
              </button>
            </div>
            <p id="gym-stat-head">Gym Information</p>
            <div className="user-stat">
              <div className="user-stat-r1">
                <div className="stat-box">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/present-weight.png`}
                      alt="weight"
                    />
                    <p>Present weight</p>
                  </span>
                  <span>
                    <h3>
                      {isLoading ? "..." : `${userProfile.weight}kg` || "N/A"}
                    </h3>
                  </span>
                </div>
                <div className="stat-box">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/present-height.png`}
                      alt="weight"
                    />
                    <p>Present height</p>
                  </span>
                  <span>
                    <h3>
                      {isLoading ? "..." : `${userProfile.height}cm` || "N/A"}
                    </h3>
                  </span>
                </div>
                <div className="stat-box">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/present-body-size.png`}
                      alt="weight"
                    />
                    <p>Present body size</p>
                  </span>
                  <span>
                    <h3>
                      {isLoading
                        ? "..."
                        : `Size ${userProfile.body_size}` || "N/A"}
                    </h3>
                  </span>
                </div>
              </div>
              <div className="user-stat-r2">
                <div className="stat-box">
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/timer.png`}
                      alt="timer"
                    />
                    <p>Total Hours Spent</p>
                  </span>
                  <span>
                    <h3>
                      {isLoading ? "..." : userProfile.total_gym_hours || "N/A"}
                    </h3>
                  </span>
                </div>
              </div>
              <div className="member-calendar">
                <Calendar
                  tileClassName={tileClassName}
                  tileContent={tileContent}
                />
              </div>
              <div className="attendance-text">
                <div className="attendance-indicator">
                  <p>hii</p>
                </div>
                <p>Session Attended</p>
              </div>
              <div className="attendance-description-outer">
                <p>Monthly attendance breakdown</p>
                <div className="attendance-description">
                  <span className="description-inner">
                    <div className="attendance-indicator">
                      <p>hii</p>
                    </div>
                    <p>Session Attended</p>
                  </span>
                  <p>{attendanceCount}</p>
                </div>
              </div>
              <style jsx>{`
                .highlighted-date {
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
                  margin: 0;
                }

                .highlighted-date:before {
                  content: "";
                  position: absolute;
                  background-color: #e0f4fe;
                  border-radius: 50%;
                  width: 35px; /* Adjust size as needed */
                  height: 90%; /* Adjust size as needed */
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1; /* Ensure the circle is behind the text */
                  text-align: center;
                  font-weight: "700";
                }

                .highlighted-date > span {
                  position: absolute;
                  z-index: 2; /* Ensure text is on top of the circle */
                  text-align: center;
                  padding: 0;
                  margin: 0;
                  color: #0473bd;
                  font-weight: "bolder";
                }
              `}</style>
            </div>
          </div>
        </div>

        <h2 id="history-head">Subscription History</h2>
        <div className="member-sub-table">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Amount</th>
                <th>Payment Date</th>
                <th>Subscription Duration</th>
                <th>Payment Method</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5">
                    <div className="spinner"></div>
                  </td>
                </tr>
              ) : (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.tnx_ref}</td>
                    <td>{item.amount}</td>
                    <td>
                      {userProfile.created_at
                        ? format(
                            parseISO(userProfile.created_at),
                            "dd MMM yyyy"
                          )
                        : "N/A"}
                    </td>
                    <td>{item.subscription_duration}</td>
                    <td>{item.payment_method}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          <Pagination
            currentPageProp={currentPage}
            totalPageProp={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            loading={loading}
          />
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleCloseModals}
        className="add-admin-modal"
      >
        <div className="form-container">
          <div className="inner-form">
            <div className="add-staff-head">
              <h2>Member Details</h2>
            </div>
            <div className="add-staff-image">
              <div className="add-staff-image-pic">
                <img
                  src={profileImage}
                  alt="Profile Preview"
                  width="36px"
                  height="36px"
                />
                <div className="add-staff-image-text">
                  <p>Upload member's picture</p>
                  <p>PNG OR JPG</p>
                </div>
              </div>
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="upload-picture"
              />
              <label htmlFor="upload-picture" style={{ cursor: "pointer" }}>
                Upload picture
              </label>
            </div>
            <div className="form-input">
              <div className="member-name">
                <div id="member-fname">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    name="firstName"
                    value={memberData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div id="member-lname">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    name="lastName"
                    value={memberData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="number"
                value={memberData.phoneNumber}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                value={memberData.email}
                type="email"
                onChange={handleInputChange}
                required
              />
              <label htmlFor="address">Address</label>
              <input
                id="address"
                name="address"
                value={memberData.address}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <div className="date-picker">
                <DatePicker
                  selected={memberData.dateOfBirth}
                  onChange={(date) => handleDateChange(date, "dateOfBirth")}
                  showPopperArrow={false}
                  dateFormat="dd-MM-yyyy" // Display format for the user
                  placeholderText="Select a date"
                />
              </div>
              <label htmlFor="profession">Profession</label>
              <input
                id="profession"
                name="profession"
                value={memberData.profession}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="NIN">National Identification Number (NIN)</label>
              <input
                id="NIN"
                name="NIN"
                value={memberData.NIN}
                type="number"
                onChange={handleInputChange}
                required
                pattern="\d{11}" // Pattern ensures exactly 11 digits
                onInvalid={(e) =>
                  e.target.setCustomValidity("NIN must be exactly 11 digits")
                }
                onInput={(e) => e.target.setCustomValidity("")} // Clears custom error message
              />
            </div>
          </div>
        </div>
        <div className="modal-button">
          <button onClick={handleCloseModals}>Cancel</button>
          <button
            style={{ backgroundColor: "#0473BD", color: "white" }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isDisableModalOpen}
        onRequestClose={handleCloseModals}
        className="delete-modal"
      >
        {userProfile && (
          <div className="inner-member">
            <img
              src={`${process.env.PUBLIC_URL}/images/disable-modal-img.png`}
              alt=""
            />
            <b>
              <p>
                Are you sure you want to disable user access for the selected
                user?
              </p>
            </b>
            <div className="details-container container">
              <div className="detail">
                <p>Name</p>
                <b>
                  <p className="D">
                    {userProfile.l_name} {userProfile.f_name}
                  </p>
                </b>
              </div>
              <div className="detail">
                <p>Gym Number</p>
                <b>
                  <p className="D">#{userProfile.id}</p>
                </b>
              </div>
              <div className="detail">
                <p>Access Status</p>
                <b>
                  <p className="D">
                    {userProfile.access_status === 1 ? "Enabled" : "Disabled"}
                  </p>
                </b>
              </div>
            </div>
            <div className="warning">
              <img src={`${process.env.PUBLIC_URL}/images/info-circle.png`} />
              <p>
                Note that disabling user access will prevent the member from
                entering the gym premises and using any associated services.
              </p>
            </div>
            <div className="inner-member-btn">
              <button onClick={handleCloseModals}>Cancel</button>
              <button
                style={{ backgroundColor: "#DD9828", color: "#FAFAFA" }}
                onClick={handleDisableUser}
              >
                Disable access
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={disableSuccess}
        onRequestClose={() => setDisableSuccess(false)}
        contentLabel="Disable Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>User Profile Disabled</h2>
        <button
          type="button"
          onClick={() => {
            setDisableSuccess(false);
            window.location.reload();
          }}
        >
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={isEnableModalOpen}
        onRequestClose={handleCloseModals}
        className="delete-modal"
      >
        {userProfile && (
          <div className="inner-member">
            <img
              src={`${process.env.PUBLIC_URL}/images/disable-modal-img.png`}
              alt=""
            />
            <b>
              <p>
                Are you sure you want to enable user access for the selected
                user?
              </p>
            </b>
            <div className="details-container container">
              <div className="detail">
                <p>Name</p>
                <b>
                  <p className="D">
                    {userProfile.l_name} {userProfile.f_name}
                  </p>
                </b>
              </div>
              <div className="detail">
                <p>Gym Number</p>
                <b>
                  <p className="D">#{userProfile.id}</p>
                </b>
              </div>
              <div className="detail">
                <p>Access Status</p>
                <b>
                  <p className="D">
                    {userProfile.access_status === 1 ? "Enabled" : "Disabled"}
                  </p>
                </b>
              </div>
            </div>
            <div className="warning">
              <img src={`${process.env.PUBLIC_URL}/images/info-circle.png`} />
              <p>
                Note that enabling user access will allow the user to have
                access to the gym premises or any associated services.
              </p>
            </div>
            <div className="inner-member-btn">
              <button onClick={handleCloseModals}>Cancel</button>
              <button
                style={{ backgroundColor: "#34B159", color: "#FAFAFA" }}
                onClick={handleEnableUser}
              >
                Grant access
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={EnableSuccess}
        onRequestClose={() => setEnableSuccess(false)}
        contentLabel="Enable Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>Access Granted</h2>
        <button
          type="button"
          onClick={() => {
            setEnableSuccess(false);
            window.location.reload();
          }}
        >
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={handleCloseModals}
        className="delete-modal"
      >
        {userProfile && (
          <div className="inner-member">
            <img
              src={`${process.env.PUBLIC_URL}/images/delete-modal-img.png`}
              alt=""
            />
            <b>
              <p>Are you sure you want to delete the selected user?</p>
            </b>
            <div className="details-container container">
              <div className="detail">
                <p>Name</p>
                <b>
                  <p className="D">
                    {userProfile.l_name} {userProfile.f_name}
                  </p>
                </b>
              </div>
              <div className="detail">
                <p>Gym Number</p>
                <b>
                  <p className="D">#{userProfile.id}</p>
                </b>
              </div>
            </div>
            <div className="warning">
              <img
                src={`${process.env.PUBLIC_URL}/images/info-circle.png`}
                alt=""
              />
              <p>
                Note that deleting this user will remove all their data
                permanently.
              </p>
            </div>
            <div className="inner-member-btn">
              <button onClick={handleCloseModals}>Cancel</button>
              <button
                style={{ backgroundColor: "red", color: "#FAFAFA" }}
                onClick={handleDeleteUser}
              >
                Delete Profile
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={deleteSuccess}
        onRequestClose={() => setDeleteSuccess(false)}
        contentLabel="Delete Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>User Profile Deleted</h2>
        <Link style={{ width: "100%" }} to="/member-management">
          <button type="button" onClick={() => setDeleteSuccess(false)}>
            Cancel{" "}
          </button>
        </Link>
      </Modal>

      <Modal
        isOpen={editSuccess}
        onRequestClose={() => setEditSuccess(false)}
        contentLabel="Details Edit Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>Profile Updated</h2>
        <p>Member profile has been updated successfully</p>
        <Link style={{ width: "100%" }}>
          <button type="button" onClick={() => setEditSuccess(false)}>
            Cancel{" "}
          </button>
        </Link>
      </Modal>

      <Modal
        isOpen={isEditModal2Open}
        onRequestClose={handleCloseModals}
        contentLabel="Gym info label"
        className="add-admin-modal"
      >
        <div className="form-container">
          <div className="inner-form">
            <div className="add-staff-head">
              <h2>Update Member stats</h2>
            </div>
            <div className="form-input">
              <label htmlFor="weight">Weight</label>
              <input
                id="weight"
                name="weight"
                value={memberData.weight}
                type="number"
                onChange={handleInputChange}
                required
              />
              <label htmlFor="height">Height</label>
              <input
                id="height"
                name="height"
                value={memberData.height}
                type="number"
                onChange={handleInputChange}
                required
              />
              <label htmlFor="bodySize">Body Size</label>
              <input
                id="bodySize"
                name="bodySize"
                value={memberData.bodySize}
                type="number"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="modal-button">
          <button onClick={handleCloseModals}>Cancel</button>
          <button
            style={{ backgroundColor: "#0473BD", color: "white" }}
            onClick={handleSave2}
          >
            Save
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={edit2Success}
        onRequestClose={() => setEdit2Success(false)}
        contentLabel="User Stat Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2 style={{ fontWeight: "500" }}>Gym Stats Updated</h2>
        <p style={{ textAlign: "center" }}>
          Member Gym stat has been updated successfully
        </p>
        <Link style={{ width: "100%" }}>
          <button type="button" onClick={() => setEdit2Success(false)}>
            Cancel{" "}
          </button>
        </Link>
      </Modal>
    </div>
  );
}
export default ViewMember;
