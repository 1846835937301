import React from "react";

const Box = ({ message, statNumber }) => {
  return (
    <div className="box container-fluid">
      <div className="box-info">
        <p className="message">{message}</p>
      </div>
      <h2 className="stat-number">{statNumber}</h2>
    </div>
  );
};

export default Box;
